@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-SoulGaze;
  @apply font-Bowdown;
  @apply font-SoulGazeItalic;

  @layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Bowden',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* input[type="radio"] {
  @apply appearance-none;
}

input[type="radio"]::-ms-check {
  @apply bg-red-500;
} */


.custom-select {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  padding-right: 1em; /* Add some padding to the right to make space for the custom arrow */
  position: relative;
}

.custom-select::-ms-expand {
  display: none; /* Remove default arrow in IE */
}

/* WebKit browsers (Chrome, Safari) */
.custom-select::-webkit-scrollbar {
  width: 12px;
}

.custom-select::-webkit-scrollbar-track {
  /* background: bg-gray-200; */
}

.custom-select::-webkit-scrollbar-thumb {
  /* background: ; */
  border-radius: 10px;
}

.custom-select::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
.custom-select {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.modal-custom-scrollbar {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  padding-right: 1em; /* Add some padding to the right to make space for the custom arrow */
  position: relative;
  overflow: auto; /* Show scrollbar only when scrollable */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* WebKit browsers (Chrome, Safari) */
.modal-custom-scrollbar::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.modal-custom-scrollbar::-webkit-scrollbar-track {
  background: #0f172a; /* Background of the scrollbar track */
}

.modal-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #166534 ; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.modal-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* Firefox */
.modal-custom-scrollbar {
  scrollbar-width:thin; /* Width of the scrollbar */
  scrollbar-color: #166534   #0f172a; /* Color of the scrollbar thumb and track */
}